import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LoadExternalScript from "../utils/load-external-script";
import { markerImageSrc } from "./map-marker-image";
import classNames from 'classnames';
import $ from 'jquery'

/* TODO: To be moved to env. or config. file */
const locRatingScriptsJs = 'https://www.locrating.com/scripts/locratingIntegrationScripts.js';

const LocRatingMap = (props) => {
    // console.log("Inside function LocRatingMap");

    let [activeCTA] = useState('location');
    // console.log('activeCTA : ' + activeCTA);

    let _data = props.data || "";
    // console.log('_data (below) : ');
    // console.log(_data);
    // console.log('typeof _data ' + typeof _data);
    // console.log('typeof _data.length : ' + typeof _data.length );
    // console.log('_data.length : ' + _data.length );

    let _mapClass = 'single'; // only one marker map
    if ( _data.length && _data.length > 1 ) { // more than one markers map
        _mapClass = 'all';
    }

    let _markerImage = markerImageSrc;
    // console.log('_markerImage : ' + _markerImage);

    let _height = 'auto'
    let _width = 'auto'

    const _iframeId = 'location-map';
    // console.log('_iframeId : ' + _iframeId);

    const _iframeTitle = "Location Map";
    // console.log('_iframeTitle : ' + _iframeTitle);

    // const _location  = "Location";
    // const _schools   = "Schools";
    // const _localInfo = "Local info";



    const renderMap = (_activeCTA) => {
        // console.log('Inside function renderMap()');
        // console.log('activeCTA : ' + activeCTA);
        if (_activeCTA) {
            activeCTA = _activeCTA;
        }
        // console.log('window (below) : ');
        // console.log(window);
        // console.log('_data (below) : ');
        // console.log(_data);

        let _type = '';
        switch (activeCTA) {
            case 'location':
            default:
                _type = 'transport';
            break;
            case 'schools':
                _type = 'schools';
            break;
            case 'local':
                _type = 'localinfo';
            break;
        }
        // console.log('_type : ' + _type);

        if (typeof window !== 'undefined') {
            const _baseUrl = window.location.origin;
            // console.log('_baseUrl : ' + _baseUrl);
            if (_markerImage.toLowerCase().indexOf(_baseUrl) === -1) { // if baseUrl not already added ...
                _markerImage =  _baseUrl + _markerImage; // ... add it
            }
            // console.log('_markerImage : ' + _markerImage);
            // console.log(typeof window.loadLocratingPlugin);
            if (typeof window.loadLocratingPlugin !== 'undefined') {
                // console.log('Inside window.loadLocratingPlugin');
                try { // try
                    // console.log('Inside try block');
                    // console.log('_data (below) : ');
                    // console.log(_data);
                    let _firstLat = '';
                    let _firstLng = '';
                    if (_data) {
                        if (_data[0]) {
                            if (_data[0].lat) {
                                _firstLat = _data[0].lat;
                            }
                            if (_data[0].lng) {
                                _firstLng = _data[0].lng;
                            }
                        }
                        // console.log("firstLat : " + _firstLat);
                        // console.log("firstLng : " + _firstLng);
                        // console.log('_iframeId : ' + _iframeId);
                        // console.log('_markerImage : ' + _markerImage);
                        // console.log('_mapClass : ' + _mapClass);
                        let _firstMarkerImage = _markerImage;
                        if ( 'all' === _mapClass ) {
                            _firstMarkerImage = '.';
                        }
                        // console.log('_firstMarkerImage : ' + _firstMarkerImage);
                        if ( 'single' === _mapClass ) {
                            _type = 'all';
                        }
                        // console.log('_type : ' + _type);
                        window.loadLocratingPlugin ({
                            id: _iframeId,
                            lat: _firstLat,
                            lng : _firstLng,
                            starttab: props.mapview ? props.mapview : 'location',
                            type: _type,
                            icon: _firstMarkerImage,
                            zoom: 11,
                            menuselectbackcolor: '#051C60',
                            menuselectcolor: '#fff',
                            showFullScreenButton: 'yes',
                            hidestationswhenzoomedout: true,
                            onLoaded: function() {
                                // console.log('Inside function onLoaded()');
                                let _n = 1;
                                for (let i in _data) {
                                    // console.log('i : ' + i);
                                    let _item = _data[i];
                                    // console.log('_item (below) : ');
                                    // console.log(_item);
                                    // console.log('_n : ' + _n);
                                    let _markerId =  _item.id;
                                    // console.log('_markerId : ' + _markerId);
                                    let _lat = _item.lat;
                                    // console.log('_lat : ' + _lat);
                                    let _lng = _item.lng;
                                    // console.log('_lng : ' + _lng);
                                    let _html = _item.html;
                                    // console.log('_html : (below) ');
                                    // console.log(_html);
                                    // console.log('_markerImage : ' + _markerImage);
                                    // console.log(typeof window.addLocratingMapMarker);
                                    if (typeof window.addLocratingMapMarker !== 'undefined') {
                                        // console.log('Inside window.addLocratingMapMarker');
                                        window.addLocratingMapMarker(_iframeId,
                                        {
                                            id: _markerId,
                                            lat: _lat,
                                            lng: _lng,
                                            html: _html,
                                            icon: _markerImage,
                                            iconHeight: _height,
                                            iconWidth: _width,
                                            zoom: 11,
                                            onClick: function(marker) {
                                                $('.area-guide-box').removeClass('area-guide-active');
                                                var target = $("#"+marker.id);
                                                if (target.length) {
                                                    $('html,body').animate({
                                                        scrollTop: target.offset().top - 250
                                                    },100);

                                                    $(target).addClass('area-guide-active');
                                                    return false;
                                                }

                                            }
                                        });
                                    }
                                    _n++;
                                } // for
                                // console.log(typeof window.centerLocratingMapUsingMarkers);
                                if (typeof window.centerLocratingMapUsingMarkers !== 'undefined') {
                                    // console.log('Inside window.centerLocratingMapUsingMarkers');
                                    //window.centerLocratingMapUsingMarkers(_iframeId);
                                }
                            } // onLoaded: function()
                        }); // window.loadLocratingPlugin
                    } // if (data)
                } catch (err) {
                     console.log(err);
                } // catch (err)
            } // if (typeof window.loadLocratingPlugin !== 'undefined')
        } // if (typeof window !== 'undefined')
    } // const renderMap = ()


    const renderMapContent = () => {
        return (
            <div id="map-holder" className={classNames('map',_mapClass)}>
                {/* <h1>LocRating Map</h1> */}
                <LoadExternalScript
                  src={locRatingScriptsJs}
                  // async="true"
                  defer="true"
                  loadScript={renderMap}
                  // appendScriptTo="head"
                />
                <iframe
                    id={_iframeId}
                    title={_iframeTitle}>
                </iframe>
            </div>
        )
    }

    if (!(props?.hasTitle))
        return (
            <>
                {renderMapContent()}
            </>
        )

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    {/* <div className="map-section d-lg-none">
                        <picture>
                            <source media="(min-width:768px)" srcSet={Img02} />
                            <img src={Img01} alt="banner" />
                        </picture>
                    </div> */}
                    <div className="content-wrapper">
                        <Row>
                            <div className="heading col-lg-3">
                                    <h2><i>Location &</i> Amenities</h2>
                            </div>
                            <div className="col-lg-9">
                                {renderMapContent()}
                            </div>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default LocRatingMap;
