import React from 'react'
import { Form } from "react-bootstrap"
import {Row, Col, Container } from 'react-bootstrap';


function myplaceholder(props) {
  return <div>{props.html}</div>;
}

function createMarkup(props) {
  return {__html: (props.html)};
}

function MyComponent(props) {
  return <div dangerouslySetInnerHTML={createMarkup(props)} />;
}
const RadioboxField = ({fieldClass, name, value, required, placeholder, handlechange, checked, lastchild, step, type, grpmd, label}) => (
<>
{type == "time_radio" ?
  <Form.Group as={Col} md={grpmd} className={`${type} ${step}`} controlId={"validation" + name}>
  <div className="radio-toolbar">
  <Row>
    <Col md={3}>
    <input type="radio" id="radio1" name="val_time" value="08:00" />
    <label for="radio1">08:00</label>
    </Col>
    <Col md={3}>
    <input type="radio" id="radio2" name="val_time" value="10:00" />
    <label for="radio2">10:00</label>
    </Col>
    <Col md={3}>
    <input type="radio" id="radio3" name="val_time" value="12:00" />
    <label for="radio3">12:00</label> 
    </Col>
    <Col md={3}>
    <input type="radio" id="radio4" name="val_time" value="14:00" />
    <label for="radio4">14:00</label> 
    </Col>
  </Row>

  <Row>
    <Col md={3}>
    <input type="radio" id="radio5" name="val_time" value="16:00" />
    <label for="radio5">16:00</label> 
    </Col>
    <Col md={3}>
    <input type="radio" id="radio6" name="val_time" value="18:00" />
    <label for="radio6">18:00</label>  
    </Col>
    <Col md={3}>
    <input type="radio" id="radio7" name="val_time" value="20:00" />
    <label for="radio7">20:00</label> 
    </Col>
    <Col md={3}>
    <input type="radio" id="radio8" name="val_time" value="21:00" />
    <label for="radio8">21:00</label> 
    </Col>
  </Row>



 
</div>
</Form.Group>
:
      <div className={`form-group radio-wrap ${ lastchild } ${ step }`}>
      <input type="radio" id={value} name={name} value={value} checked={checked} />
      <label className="radio-label" for={value}>
        {label}
      </label>
      <span className="radio-ico"></span>
  </div>
  }
</>
);

export default RadioboxField;
